var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('billing-form',{on:{"cancel":function($event){_vm.showDialog = false},"data-saved":function($event){_vm.showDialog = false;
        _vm.fetchBills();}}})],1),_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"filters"},[_c('v-btn',{attrs:{"rounded":"","icon":""}},[_c('v-icon',[_vm._v("mdi-filter-variant")])],1),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"0 2px"},attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v("sort by: "+_vm._s(_vm.selectedStaff))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',_vm._l((['All staff']),function(item){return _c('v-list-item',{key:item,on:{"click":function($event){_vm.selectedStaff = item}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1),_c('v-autocomplete',{staticClass:"search-field",attrs:{"items":_vm.billingPage.docs,"item-text":"name","item-value":"_id","height":"20","label":"Search"},on:{"change":_vm.viewBilling},model:{value:(_vm.billing),callback:function ($$v) {_vm.billing=$$v},expression:"billing"}})],1),_c('div',{staticClass:"extra-btns"},[_c('v-btn',{attrs:{"elevation":"0","color":"primary"},on:{"click":function($event){_vm.showDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add a bill ")],1)],1)])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.billingPage.docs,"options":_vm.options,"server-items-length":_vm.billingPage.total,"no-data-text":"No bills to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableDate")(item.date,"DD MMM YYYY"))+" ")]}},{key:"item.frequency",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(item.frequency))])]}},{key:"itemployeeem.status",fn:function(ref){
        var item = ref.item;
return [(item.status === 'paid')?_c('v-chip',{attrs:{"color":"#d3f9db"}},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(item.status))])]):_vm._e(),(item.status === 'overdue')?_c('v-chip',{attrs:{"color":"#fcf1d0"}},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(item.status))])]):_vm._e(),(item.status === 'due')?_c('v-chip',{attrs:{"color":"#facccc"}},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(item.status))])]):_vm._e()]}},{key:"item.tax",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(item.tax))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }