





































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import billingStoreModule from "@/store/modules/billing";
import { Business, Role } from "@/types";

const { mapActions: billingActions } = createNamespacedHelpers("BILLING_");

export default Vue.extend<any, any, any, any>({
  name: "BillingForm",
  data() {
    return {
      valid: false,
      currency: "KES",
      name: "",
      tax: "",
      cost: "",
      date: "",
      description: "",
      frequency: "",
      nameRules: [(v: string) => !!v || "Name is required"],
      taxRules: [(v: string) => !!v || "Taxation is required"],
      costRules: [(v: string) => !!v || "Cost is required"],
      dateRules: [(v: string) => !!v || "Date is required"],
      descriptionRules: [(v: string) => !!v || "Description is required"],
      frequencyRules: [(v: string) => !!v || "Staff is required"],
      image: undefined as undefined | File,
    };
  },
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
  },
  mounted() {
    this.resetForm();
  },
  methods: {
    ...billingActions(["createBilling"]),
    validateForm() {
      const valid = (
        this.$refs.billingForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      const payload = {
        name: this.name,
        tax: this.tax,
        cost: this.cost,
        description: this.description,
        frequency: this.frequency,
        date: this.date,
        currency: this.currency,
        businessId: (this.role?.business as Business)._id,
      };
      this.createBilling(payload).then((billing) => {
        if (billing) this.$emit("data-saved");
      });
    },
    resetForm() {
      this.name = "";
      this.tax = "";
      this.date = "";
      this.cost = "";
      this.description = "";
      this.frequency = "";
      (
        this.$refs.billingForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    cancel() {
      this.$emit("cancel", true);
    },
    dropHandler(ev: DragEvent) {
      const file = ev.dataTransfer?.files[0] as File;

      this.image = file;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("BILLING_")) {
      this.$store.registerModule("BILLING_", billingStoreModule);
    }
  },
  beforeDestroy() {
    // this.$store.unregisterModule("BILLING_");
  },
});
