





















































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import billingStoreModule from "@/store/modules/billing";
import { Billing, Business, Role } from "@/types";
import BillingForm from "./BillingForm.vue";

const { mapActions: billingActions, mapGetters: billingGetters } =
  createNamespacedHelpers("BILLING_");

const API_URL = process.env.VUE_APP_API_URL;
export default Vue.extend<any, any, any, any>({
  components: { BillingForm },
  name: "BillingList",
  data: () => ({
    showDialog: false,
    selectedStaff: "All staff",
    billing: undefined as undefined | Billing,
    appUrl: API_URL,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Date", value: "date" },
      { text: "Cost", value: "cost" },
      { text: "Frequency", value: "frequency" },
      { text: "Tax", value: "tax" },
      { text: "Status", value: "status" },
      { text: "Description", value: "description" },
    ],
    options: {} as { page: number },
  }),
  watch: {
    role() {
      if (this.role) {
        this.fetchBills();
      }
    },
    options: {
      handler() {
        this.fetchBills();
      },
      deep: true,
    },
  },
  computed: {
    ...billingGetters(["billingPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...billingActions(["fetchBillingList"]),
    viewBilling(billing: Billing): void {
      this.billing = billing;
    },
    fetchBills() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        const params = `?businessId=${bid}&page=${this.options.page || 1}`;
        this.fetchBillingList(params);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("BILLING_")) {
      this.$store.registerModule("BILLING_", billingStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("BILLING_");
  },
});
