var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('expense-form',{on:{"cancel":function($event){_vm.showDialog = false},"data-saved":function($event){_vm.showDialog = false}}})],1),_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"filters"},[_c('v-btn',{attrs:{"rounded":"","icon":""}},[_c('v-icon',[_vm._v("mdi-filter-variant")])],1),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"0 2px"},attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v("sort by: "+_vm._s(_vm.selectedStaff))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',_vm._l((['All staff']),function(item){return _c('v-list-item',{key:item,on:{"click":function($event){_vm.selectedStaff = item}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1),_c('v-autocomplete',{staticClass:"search-field",attrs:{"items":_vm.expensePage.docs,"item-text":"client.fullName","item-value":"_id","height":"20","label":"Search"},on:{"change":_vm.viewExpense},model:{value:(_vm.expense),callback:function ($$v) {_vm.expense=$$v},expression:"expense"}})],1),_c('div',{staticClass:"extra-btns"},[_c('v-btn',{attrs:{"elevation":"0","color":"primary"},on:{"click":function($event){_vm.showDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add an expense ")],1)],1)])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.expensePage.docs,"options":_vm.options,"server-items-length":_vm.expensePage.total,"no-data-text":"No expenses to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableDate")(item.date,"DD MMM YYYY"))+" ")]}},{key:"item.employee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.employee.fullName)+" ")]}},{key:"item.paymentMethod",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.paymentMethod.toUpperCase())+" ")]}},{key:"item.receiptPhoto",fn:function(ref){
var item = ref.item;
return [(item.receiptPhoto)?_c('img',{staticStyle:{"width":"100px"},attrs:{"src":(_vm.appUrl + "/v1/file/" + (item.receiptPhoto.filename)),"alt":item.name}}):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }