





















import ActionButtons from "@/components/ActionButtons.vue";
import BillingList from "@/components/payment/BillingList.vue";
import ExpenseList from "@/components/payment/ExpenseList.vue";
import PaymentList from "@/components/payment/PaymentList.vue";
import Vue from "vue";

export default Vue.extend<any, any, any, any>({
  components: {
    PaymentList,
    ExpenseList,
    BillingList,
    ActionButtons,
  },
  name: "Payments",
});
