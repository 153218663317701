


















































































































































import { Client, Payment, Product } from "@/types";
import Vue, { PropType } from "vue";

const API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  name: "PaymentDetails",
  props: {
    payment: {
      type: Object as PropType<Payment>,
      required: true,
    },
  },
  data: () => ({
    currency: "KES",
    apiUrl: API_URL,
  }),
  computed: {
    avatarSrc() {
      const avatar = (this.payment?.client as Client).avatar;
      return `${this.apiUrl}/v1/file/${avatar.filename}`;
    },
  },
  methods: {
    getImageSrc(product: Product) {
      const img = product.images[0];
      return `${this.apiUrl}/v1/file/${img.filename}`;
    },
  },
});
