















































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import expenseStoreModule from "@/store/modules/expense";
import { Business, Expense, Role } from "@/types";
import ExpenseForm from "./ExpenseForm.vue";

const { mapActions: expenseActions, mapGetters: expenseGetters } =
  createNamespacedHelpers("EXPENSE_");

const API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  components: { ExpenseForm },
  name: "ExpenseList",
  data: () => ({
    showDialog: false,
    selectedStaff: "All staff",
    expense: undefined as undefined | Expense,
    appUrl: API_URL,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Type", value: "type" },
      { text: "Amount", value: "amount" },
      { text: "Date", value: "date" },
      { text: "Staff", value: "employee" },
      { text: "Payment", value: "paymentMethod" },
      { text: "Receipt", value: "receiptPhoto" },
      { text: "Description", value: "description" },
    ],
    options: {} as { page: number },
  }),
  watch: {
    role() {
      if (this.role) {
        this.fetchExpenses();
      }
    },
    options: {
      handler() {
        this.fetchExpenses();
      },
      deep: true,
    },
  },
  computed: {
    ...expenseGetters(["expensePage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...expenseActions(["fetchExpenseList"]),
    viewExpense(expense: Expense): void {
      this.expense = expense;
    },
    fetchExpenses() {
      if (this.role) {
        const params = `?businessId=${
          (this.role.business as Business)._id
        }&page=${this.options.page || 1}`;
        this.fetchExpenseList(params);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("EXPENSE_")) {
      this.$store.registerModule("EXPENSE_", expenseStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("EXPENSE_");
  },
});
