import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Billing, Page } from "@/types";

type BillingState = {
  billingPage: Page<Billing>;
};

const billing: Module<BillingState, unknown> = {
  namespaced: true,
  state: () => ({
    billingPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getBilling: (state) => (billingId: string) =>
      state.billingPage.docs.find((e) => e._id === billingId),
    billingPage: (state) => state.billingPage,
  },
  mutations: {
    ADD_BILLING: (state, _billing) => {
      let idx = -1;
      state.billingPage.docs.map((e, i) => {
        if (e._id === _billing._id) idx = i;
      });
      if (idx === -1) state.billingPage.docs.push(_billing);
      else Vue.set(state.billingPage.docs, idx, _billing);
    },
    SET_BILLING_PAGE: (state, list) => {
      state.billingPage = list;
    },
    REMOVE_BILLING(state, billing) {
      let idx = -1;
      state.billingPage.docs.map((r, i) => {
        if (r._id === billing._id) idx = i;
      });
      if (idx > -1) state.billingPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchBilling(context, params = "") {
      api
        .get(`/v1/billing${params}`)
        .then((response) => {
          context.commit("ADD_BILLING", response.data.billing);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchBillingList(context, params = "") {
      api
        .get(`/v1/billing${params}`)
        .then((response) => {
          context.commit("SET_BILLING_PAGE", response.data.billingPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createBilling(context, payload) {
      return await api
        .post(`/v1/billing`, payload)
        .then((response) => {
          context.commit("ADD_BILLING", response.data.billing);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Billing created",
            },
            { root: true }
          );
          return response.data.billing;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updateBilling(context, data: { id: string; billing: Billing }) {
      api
        .put(`/v1/billing/${data.id}`, data.billing)
        .then((response) => {
          context.commit("ADD_BILLING", response.data.billing);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Billing updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteBilling(context, id) {
      api
        .delete(`/v1/billing${id}`)
        .then((response) => {
          context.commit("REMOVE_BILLING", response.data.billing);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Billing deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default billing;
