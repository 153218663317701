import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Expense, Page } from "@/types";

type ExpenseState = {
  expensePage: Page<Expense>;
};

const expense: Module<ExpenseState, unknown> = {
  namespaced: true,
  state: () => ({
    expensePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getExpense: (state) => (expenseId: string) =>
      state.expensePage.docs.find((e) => e._id === expenseId),
    expensePage: (state) => state.expensePage,
  },
  mutations: {
    ADD_EXPENSE: (state, _expense) => {
      let idx = -1;
      state.expensePage.docs.map((e, i) => {
        if (e._id === _expense._id) idx = i;
      });
      if (idx === -1) state.expensePage.docs.push(_expense);
      else Vue.set(state.expensePage.docs, idx, _expense);
    },
    SET_EXPENSE_PAGE: (state, list) => {
      state.expensePage = list;
    },
    REMOVE_EXPENSE(state, expense) {
      let idx = -1;
      state.expensePage.docs.map((r, i) => {
        if (r._id === expense._id) idx = i;
      });
      if (idx > -1) state.expensePage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchExpense(context, params = "") {
      api
        .get(`/v1/expense${params}`)
        .then((response) => {
          context.commit("ADD_EXPENSE", response.data.expense);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchExpenseList(context, params = "") {
      api
        .get(`/v1/expense${params}`)
        .then((response) => {
          context.commit("SET_EXPENSE_PAGE", response.data.expensePage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createExpense(context, payload) {
      return await api
        .post(`/v1/expense`, payload)
        .then((response) => {
          context.commit("ADD_EXPENSE", response.data.expense);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Expense created",
            },
            { root: true }
          );
          return response.data.expense;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updateExpense(context, data: { id: string; expense: Expense }) {
      api
        .put(`/v1/expense/${data.id}`, data.expense)
        .then((response) => {
          context.commit("ADD_EXPENSE", response.data.expense);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Expense updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteExpense(context, id) {
      api
        .delete(`/v1/expense${id}`)
        .then((response) => {
          context.commit("REMOVE_EXPENSE", response.data.expense);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Expense deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default expense;
