
















































































































import Vue, { VueConstructor } from "vue";
import { createNamespacedHelpers } from "vuex";

import expenseStoreModule from "@/store/modules/expense";
import employeeStoreModule from "@/store/modules/employee";
import { Business, Role } from "@/types";

const { mapActions: employeeActions, mapGetters: employeeGetters } =
  createNamespacedHelpers("EMPLOYEE_LIST_EXPENSE");

const { mapActions: expenseActions } = createNamespacedHelpers("EXPENSE_");

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        imageField: HTMLInputElement;
      };
    }
  >
).extend<any, any, any, any>({
  name: "ExpenseForm",
  data() {
    return {
      valid: false,
      currency: "KES",
      name: "",
      type: "",
      amount: "",
      date: "",
      description: "",
      paymentMethod: "",
      staffId: "",
      nameRules: [(v: string) => !!v || "Name is required"],
      typeRules: [(v: string) => !!v || "Type is required"],
      amountRules: [(v: string) => !!v || "Price is required"],
      dateRules: [(v: string) => !!v || "Date is required"],
      descriptionRules: [(v: string) => !!v || "Description is required"],
      staffRules: [(v: string) => !!v || "Staff field is required"],
      paymentMethodRules: [(v: string) => !!v || "Description is required"],
      image: undefined as undefined | File,
    };
  },
  computed: {
    ...employeeGetters(["employeePage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  created() {
    if (this.role) {
      const bid = this.role.business._id;
      this.fetchEmployeeList(`?businessId=${bid}`);
    }
  },
  mounted() {
    this.resetForm();
  },
  methods: {
    ...expenseActions(["createExpense"]),
    ...employeeActions(["fetchEmployeeList"]),
    validateUpload() {
      this.image = (this.$refs.imageField.files || [])[0];
    },
    validateForm() {
      const valid = (
        this.$refs.expenseForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      const image = this.image as File;

      const formData = new FormData();

      if (image) formData.append("file", image);
      formData.append("name", this.name);
      formData.append("type", this.type);
      formData.append("amount", this.amount);
      formData.append("paymentMethod", this.paymentMethod.toLowerCase());
      formData.append("employeeId", this.staffId);
      formData.append("description", this.description);
      formData.append("date", this.date);
      formData.append("businessId", (this.role?.business as Business)._id);
      formData.append("currency", this.currency);
      this.createExpense(formData).then((expense) => {
        if (expense) this.$emit("data-saved");
      });
    },
    resetForm() {
      this.name = "";
      this.type = "";
      this.date = "";
      this.amount = "";
      this.description = "";
      this.paymentMethod = "";
      this.staffId = "";
      (
        this.$refs.expenseForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    cancel() {
      this.$emit("cancel", true);
    },
    dropHandler(ev: DragEvent) {
      const file = ev.dataTransfer?.files[0] as File;

      this.image = file;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("EXPENSE_")) {
      this.$store.registerModule("EXPENSE_", expenseStoreModule);
    }
    if (!this.$store.hasModule("EMPLOYEE_LIST_EXPENSE")) {
      this.$store.registerModule("EMPLOYEE_LIST_EXPENSE", employeeStoreModule);
    }
  },
  beforeDestroy() {
    // this.$store.unregisterModule("EXPENSE_");
    this.$store.unregisterModule("EMPLOYEE_LIST_EXPENSE");
  },
});
